import React from 'react';
import { FormattedMessage, FormattedDate } from '../../util/reactIntl';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { LINE_ITEM_NIGHT, LINE_ITEM_UNITS, DATE_TYPE_DATE, propTypes } from '../../util/types';
import { tipsWithTimeZone, formatTimeWithTimeZone } from '../../util/dates';

const formatMonth = 'MMM DD';
const formatDay = 'dddd';
const formatTime = 'h:mma';

import css from './BookingBreakdown.module.css';

const BookingPeriod = props => {
  const { startDate, endDate, dateType, timeZone } = props;
  const a = moment(endDate);
  const b = moment(startDate);
  const diff = a.diff(b, 'hours');

  const startTime = timeZone ? formatTimeWithTimeZone(startDate, timeZone, formatTime) : moment(startDate).format(formatTime);
  const endTime = timeZone ? formatTimeWithTimeZone(endDate, timeZone, formatTime) : moment(endDate).format(formatTime);



  return (
    <div className={css.bookingDates}>
      {timeZone && <span className={css.timeZoneTip}>{tipsWithTimeZone(timeZone)}</span>}
      



      <div className={css.dayInfoHolder}>
        <div className={css.dayInfo}>
          <label className={css.dayInfoLabel}>
            Start Date
          </label>
          <div className={css.dayInfoTime}>
            {timeZone ? moment(startDate).tz(timeZone).format(formatDay).substring(0,3) : moment(startDate).format(formatDay).substring(0,3)}
            {" "}
            {startTime.replace("pm", " pm").replace("am", " am").toUpperCase()}
            {" "}
            {!!timeZone && `(${timeZone})`}
          </div>
          <div className={css.dayInfoDay}>
            {timeZone ? moment(startDate).tz(timeZone).format(formatMonth) : moment(startDate).format(formatMonth)}
          </div>
        </div>


        <div className={css.dayInfo}>
          <label className={css.dayInfoLabel}>
            End Date
          </label>
          <div className={css.dayInfoTime}>
            {timeZone ? moment(startDate).tz(timeZone).format(formatDay).substring(0,3) : moment(startDate).format(formatDay).substring(0,3)}
            {" "}
            {endTime.replace("pm", " pm").replace("am", " am").toUpperCase()}
            {" "}
            {!!timeZone && `(${timeZone})`}
          </div>
          <div className={css.dayInfoDay}>
            {timeZone ? moment(startDate).tz(timeZone).format(formatMonth) : moment(startDate).format(formatMonth)}
          </div>
        </div>
      </div>

      {/* <div className={css.itemLabelDate}>
        {timeZone
          ? formatTimeWithTimeZone(startDate, timeZone, formatTime)
          : moment(startDate).format(formatTime)}{' '}
        -{' '}
        {timeZone
          ? formatTimeWithTimeZone(endDate, timeZone, formatTime)
          : moment(endDate).format(formatTime)}
        <span className={css.divider}>= {diff} {diff == 1 ? "hour" : "hours"}</span>
      </div> */}
    </div>
  );
};
const LineItemBookingPeriod = props => {
  const { booking, unitType, dateType, timeZone, inbox } = props;

  // Attributes: displayStart and displayEnd can be used to differentiate shown time range
  // from actual start and end times used for availability reservation. It can help in situations
  // where there are preparation time needed between bookings.
  // Read more: https://www.sharetribe.com/api-reference/marketplace.html#bookings
  const { start, end, displayStart, displayEnd } = booking.attributes;
  const localStartDate = displayStart || start;
  const localEndDateRaw = displayEnd || end;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isUnit = unitType === LINE_ITEM_UNITS;
  const endDay =
    isUnit || isNightly ? localEndDateRaw : moment(localEndDateRaw).subtract(1, 'days');

  return (
    <>
      <div className={css.lineItem}>
        <BookingPeriod
          startDate={localStartDate}
          endDate={endDay}
          dateType={dateType}
          timeZone={timeZone}
        />
      </div>
      {/* {!inbox && <hr className={css.totalDivider} />} */}
      <hr className={css.totalDivider} />
    </>
  );
};
LineItemBookingPeriod.defaultProps = { dateType: null };

LineItemBookingPeriod.propTypes = {
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,
};

export default LineItemBookingPeriod;
