import moment from 'moment';
import { denormalisedResponseEntities } from '../util/data';

// ================ Action types ================ //

const HANDLE_AVAILABILITY_CHECK_REQUEST = 'app/cart/HANDLE_AVAILABILITY_CHECK_REQUEST';
const HANDLE_AVAILABILITY_CHECK_COMPLETE = 'app/cart/HANDLE_AVAILABILITY_CHECK_COMPLETE';

export const MANAGE_CART = 'app/cart/MANAGE_CART';
export const CLEAR_CART = 'app/cart/CLEAR_CART';

const storageName = userId => `cart-${userId}`;

const hasWindow = () => typeof window !== 'undefined';

const managePastDates = cart => {
  const now = moment();
  const result = cart && Object.keys(cart).reduce((obj, key) => {
    const newDates = cart[key].filter(({startDate}) => moment(startDate).isAfter(now));
    return {...obj, ...(newDates.length ? {[key]: newDates} : {})};
  }, {});

  return result && Object.keys(result).length ? result : null;
}

const getFromStorage = userId => {
  if (!userId || !hasWindow()){
    return;
  }
  const cart = window.localStorage.getItem(storageName(userId));

  const managedCart = cart && managePastDates(JSON.parse(cart)) || null;

  manageStorage(userId, managedCart);

  return managedCart;
}

const manageStorage = (userId, cart) => {
  if (!userId || !hasWindow()){
    return;
  }

  if (!cart || !Object.keys(cart).length){
    window.localStorage.removeItem(storageName(userId));
    return;
  }

  window.localStorage.setItem(storageName(userId), JSON.stringify(cart));
}

const clearStorage = (userId) => {
  if (!userId || !hasWindow()){
    return;
  }

  window.localStorage.removeItem(storageName(userId));
}

const prepareDates = dates => dates.sort((a, b) => {
  return !moment(a.startDate).isSame(moment(b.startDate)) ? moment(a.startDate).isAfter(moment(b.startDate)) ? 1 : -1 : 0
})

const dropDatesFromCart = (dropDates, cart) => {
  if (!cart){
    return null;
  }

  const updatedCart = Object.keys(cart).reduce((obj, listingId) => {
    const dates = cart[listingId] && cart[listingId].filter(({startDate}) => {
      return !dropDates[listingId] || !dropDates[listingId].find(filtered => filtered.startDate === startDate)
    })

    return {
      ...obj,
      ...(dates && dates.length ? {[listingId]: dates} : {})
    }
  }, {})

  return Object.keys(updatedCart).length ? updatedCart : null;
} 

// ================ Reducer ================ //

const initialState = {
 cart: null,
 cartFetched: false,
 availabilityCheckInProgress: false 
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case MANAGE_CART:
      const initCartFetched = !state.cartFetched ? {cartFetched: true} : {};
      return { ...state, cart: payload, ...initCartFetched};

    case CLEAR_CART:
      return {
        ...initialState
      };

    case HANDLE_AVAILABILITY_CHECK_REQUEST:
      return { ...state, availabilityCheckInProgress: true };

    case HANDLE_AVAILABILITY_CHECK_COMPLETE:
      return { ...state, availabilityCheckInProgress: false };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const manageCart = payload => ({ type: MANAGE_CART, payload });
export const clearCart = () => ({ type: CLEAR_CART });


export const handleAvailabilityCheckRequest = () => ({ type: HANDLE_AVAILABILITY_CHECK_REQUEST });
export const handleAvailabilityCheckComplete = () => ({ type: HANDLE_AVAILABILITY_CHECK_COMPLETE });


// ===================== Thunks =================== //


const currentUserId = getState => {
  const currentUser = getState().user.currentUser;
  return currentUser && currentUser.id && currentUser.id.uuid || null;
}

export const manageClearCart = (dropDates = null) => (dispatch, getState) => {
  const userId = currentUserId(getState);

  if (!dropDates){
    dispatch(clearCart());
    clearStorage(userId);
  }

  const {cart} = getState().cart;
  const updatedCart = dropDatesFromCart(dropDates, cart);

  if (!updatedCart){
    dispatch(clearCart());
    clearStorage(userId);
  }

  dispatch(manageCart(updatedCart));
  manageStorage(userId, updatedCart);
}

export const manageAddToCart = params => (dispatch, getState) => {
  const { listingId, dates, bookingAdditionalInfo } = params;
  const { cart } = getState().cart || {};

  const currentCart = cart && cart[listingId];
  const preparedDates = prepareDates(dates).map(({startDate, endDate}) => {
    const unavailable = currentCart && currentCart.find(d => (moment(startDate).isSame(d.startDate)) && d.unavailable);
    return {
      startDate,
      endDate,
      unavailable,
      bookingAdditionalInfo
    }
  })

  const newCart = {
    ...cart,
      [listingId]: preparedDates,
  };

  //save to storage
  const userId = currentUserId(getState);
  manageStorage(userId, newCart);

  return dispatch(manageCart(newCart));
}

export const manageRemoveFromCart = params => (dispatch, getState) => {
  const { listingId, dates } = params;
  const { cart } = getState().cart || {};

  if (!cart || !cart[listingId]){
    return;
  }

  const newCart = Object.keys(cart).reduce((obj, key) => {
    if (key !== listingId){
      return {...obj, [key]: cart[key]};
    }

    const newDatesList = cart[key].filter(({startDate}) => !(moment(startDate).isSame(dates.startDate)));
    if (!newDatesList.length){
      return obj
    }

    return {...obj, [key]: newDatesList};
  }, {});

  //save to storage
  const userId = currentUserId(getState);
  manageStorage(userId, newCart);

  const newCartMaybe = Object.keys(newCart).length ? newCart : null;
  return dispatch(manageCart(newCartMaybe));
}

export const initCart = () => async (dispatch, getState) => {
  const { cartFetched } = getState().cart;

  if (cartFetched){
    return;
  }

  const userId = currentUserId(getState);
  const cart = getFromStorage(userId);

  if (!!cart){
    await dispatch(getUnavailableDates(cart, true));
    return;
  }
  dispatch(manageCart(cart));
}

export const handleUnavailableDates = (invalidDates, initDates) => (dispatch, getState) => {
  const dates = initDates || getState().cart.cart;

  const updatedCart = Object.keys(dates).reduce((obj, listingId) => {
    if (!dates[listingId]){
      return obj;
    }

    return {
      ...obj,
      [listingId]: dates[listingId].map(d => {
        if (invalidDates && invalidDates[listingId] && invalidDates[listingId].some(({startDate}) => moment(d.startDate).isSame(startDate))){
          return {...d, unavailable: true}
        }

        return d;
      })
    }
  }, {})

  const userId = currentUserId(getState);

  dispatch(manageCart(updatedCart));
  manageStorage(userId, updatedCart);
}

// ============ ADDITIONAL LOGIC ============ //

export const fetchTimeSlots = (listingId, start, end, timeZone) => async (dispatch, getState, sdk) => {
  const params = {
    listingId,
    start,
    end,
    per_page: 500,
    page: 1,
  };

  try {
    const response = await sdk.timeslots.query(params);
    const slots = denormalisedResponseEntities(response);
    return slots;
  } catch(e){
    return null
  }
};

const getUnavailableDatesByListing = (listingId, dates) => async (dispatch, getState, sdk) => {
  const sortedDates = dates.sort((a, b) => {
    return !moment(a.startDate).isSame(moment(b.startDate)) ?
           moment(a.startDate).isAfter(moment(b.startDate)) ?
           1 : -1 : 0
  })

  const start = sortedDates[0].startDate;
  const end = sortedDates[sortedDates.length - 1].endDate;

  try {
    const slots = await dispatch(fetchTimeSlots(listingId, start, end));
    const unavailableDates = slots ? sortedDates.filter(({startDate, endDate}) => {
      return !slots.find(({attributes: {start, end}}) => moment(start).isSameOrBefore(startDate) && moment(end).isSameOrAfter(endDate) )
    }) : null;

    return (
      unavailableDates && unavailableDates.length ? {
        listingId,
        dates:unavailableDates
      } : null
    );
  } catch(e){
    return null;
  }
}

export const getUnavailableDates = (dates, init = false) => async dispatch => {
  dispatch(handleAvailabilityCheckRequest());

  try {
    const requests = Object.entries(dates).map(([listingId, dates]) => dispatch(getUnavailableDatesByListing(listingId, dates)))
    const results = await Promise.all(requests);
    const filteredResults = results.filter(r => !!r);

    const unavailableDates = filteredResults.length ? filteredResults.reduce((obj, {listingId, dates}) => {
      return {
        ...obj,
        [listingId]: dates
      }
    }, {}) : null;

    const initDates = init ? dates : null;

    dispatch(handleUnavailableDates(unavailableDates, initDates));
    dispatch(handleAvailabilityCheckComplete());
    return Promise.resolve(unavailableDates);
  } catch(e){
    dispatch(handleAvailabilityCheckComplete());
    console.error(e)
    return Promise.resolve(null);
  }
}