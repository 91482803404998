import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';



const LineItemAdditionalInfoMaybe = props => {
  const { transaction, intl, bookingAdditionalInfo } = props;

  const brand = bookingAdditionalInfo?.brand || transaction?.attributes?.protectedData?.brand;
  const event = bookingAdditionalInfo?.event || transaction?.attributes?.protectedData?.event;
  const location = bookingAdditionalInfo?.location?.search || transaction?.attributes?.protectedData?.location;

  console.log(transaction, "BookingBreakdown");

  return (
    <div className={css.lineItemAdditionalInfo}>
      {brand && (
        <div className={css.itemLabel}>
          {brand}
        </div>
      )}

      {event && (
        <div className={css.itemLabel}>
          {event}
        </div>
      )}

      {!!location && (
        <div className={css.itemLabel}>
          {location}
        </div>
      )}
    </div>
  )
};

LineItemAdditionalInfoMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemAdditionalInfoMaybe;
