import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { tipsWithTimeZone, formatTimeWithTimeZone } from '../../util/dates';

import css from './BookingBreakdown.module.css';

const formatMonth = 'MMM DD';
const formatDay = 'dddd';
const formatTime = 'h:mma';

const BookingPeriod = props => {
  const { startDate, endDate, dateType, timeZone } = props;
  const a = moment(endDate);
  const b = moment(startDate);
  const diff = a.diff(b, 'hours');

  const startTime = timeZone ? formatTimeWithTimeZone(startDate, timeZone, formatTime) : moment(startDate).format(formatTime);
  const endTime = timeZone ? formatTimeWithTimeZone(endDate, timeZone, formatTime) : moment(endDate).format(formatTime);



  return (
    <div className={css.bookingDates}>
      {timeZone && <span className={css.timeZoneTip}>{tipsWithTimeZone(timeZone)}</span>}
      
      <div className={css.dayInfoHolder}>
        <div className={css.dayInfo}>
          <label className={css.dayInfoLabel}>
            Start Date
          </label>
          <div className={css.dayInfoTime}>
            {timeZone ? moment(startDate).tz(timeZone).format(formatDay).substring(0,3) : moment(startDate).format(formatDay).substring(0,3)}
            {" "}
            {startTime.replace("pm", " pm").replace("am", " am").toUpperCase()}
            {" "}
            {!!timeZone && `(${timeZone})`}
          </div>
          <div className={css.dayInfoDay}>
            {timeZone ? moment(startDate).tz(timeZone).format(formatMonth) : moment(startDate).format(formatMonth)}
          </div>
        </div>


        <div className={css.dayInfo}>
          <label className={css.dayInfoLabel}>
            End Date
          </label>
          <div className={css.dayInfoTime}>
            {timeZone ? moment(startDate).tz(timeZone).format(formatDay).substring(0,3) : moment(startDate).format(formatDay).substring(0,3)}
            {" "}
            {endTime.replace("pm", " pm").replace("am", " am").toUpperCase()}
            {" "}
            {!!timeZone && `(${timeZone})`}
          </div>
          <div className={css.dayInfoDay}>
            {timeZone ? moment(startDate).tz(timeZone).format(formatMonth) : moment(startDate).format(formatMonth)}
          </div>
        </div>
      </div>

      {/* <div className={css.itemLabelDate}>
        {timeZone
          ? formatTimeWithTimeZone(startDate, timeZone, formatTime)
          : moment(startDate).format(formatTime)}{' '}
        -{' '}
        {timeZone
          ? formatTimeWithTimeZone(endDate, timeZone, formatTime)
          : moment(endDate).format(formatTime)}
        <span className={css.divider}>= {diff} {diff == 1 ? "hour" : "hours"}</span>
      </div> */}
    </div>
  );
};

const LineItemMultiplePeriods = props => {
  const { selectedDates, dateType, timeZone, inbox } = props;
  
  return (
    <>
      {selectedDates.map(({ startDate, endDate }) => (
        <div className={css.lineItem} key={moment(startDate).format()}>
          <BookingPeriod
            startDate={startDate}
            endDate={endDate}
            dateType={dateType}
            timeZone={timeZone}
          />         
        </div>
      ))}
      {!inbox && <hr className={css.totalDivider} />}
    </>
  );
};

export default LineItemMultiplePeriods;
