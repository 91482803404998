import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, object, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import {
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  txIsAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsEnquired,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsRequested,
  txHasBeenDelivered,
} from '../../util/transaction';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { formatMoney } from '../../util/currency';
import {
  AvatarLarge,
  BookingPanel,
  NamedLink,
  ReviewModal,
  UserDisplayName,
  FieldCheckbox,
  Form,
  Modal,
} from '../../components';
import { SendMessageForm } from '../../forms';
import config from '../../config';

// These are internal components that make this file more readable.
import AddressLinkMaybe from './AddressLinkMaybe';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import PanelHeading, {
  HEADING_ENQUIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_REQUESTED,
  HEADING_ACCEPTED,
  HEADING_DECLINED,
  HEADING_CANCELED,
  HEADING_DELIVERED,
} from './PanelHeading';

import css from './TransactionPanel.module.css';

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      reviewSubmitted: false,
      releaseShow: false,
      accidentShow: false,
      agreeTerms: false,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(r => this.setState({ isReviewModalOpen: false, reviewSubmitted: true }))
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  setAgreeTerms(e) {
    this.setState({ agreeTerms: e });
  }

  setReleaseShow(e) {
    this.setState({ releaseShow: e });
  }

  setAccidentShow(e) {
    this.setState({ accidentShow: e });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { files } = values;
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);
    if (!message && !files) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message, files)
      .then(messageId => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onFetchTimeSlots,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onDeclineSale,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      onSubmitBookingRequest,
      monthlyTimeSlots,
      nextTransitions,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      filesInProgress,
      messagesFiles,
      fetchFilesError,
      uploadFilesError,
      bookingAdditionalInfo,
      setBookingAdditionalInfo
    } = this.props;

    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';

    const providerUUID = currentTransaction.provider.id.uuid;
    const selectedDates =
      currentTransaction?.attributes?.protectedData?.selectedDates
        ? currentTransaction?.attributes?.protectedData?.selectedDates
        : currentTransaction?.booking?.attributes?.start
    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;

    const brand = currentTransaction?.attributes?.protectedData?.brand;
    const event = currentTransaction?.attributes?.protectedData?.event;
    const eventLocation = currentTransaction?.attributes?.protectedData?.location;

    const stateDataFn = tx => {
      if (txIsEnquired(tx)) {
        const transitions = Array.isArray(nextTransitions)
          ? nextTransitions.map(transition => {
            return transition.attributes.name;
          })
          : [];
        const hasCorrectNextTransition =
          transitions.length > 0 && transitions.includes(TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY);
        return {
          headingState: HEADING_ENQUIRED,
          showBookingPanel: isCustomer && !isProviderBanned && hasCorrectNextTransition,
        };
      } else if (txIsPaymentPending(tx)) {
        return {
          headingState: HEADING_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsPaymentExpired(tx)) {
        return {
          headingState: HEADING_PAYMENT_EXPIRED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsRequested(tx)) {
        return {
          headingState: HEADING_REQUESTED,
          showDetailCardHeadings: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
        };
      } else if (txIsAccepted(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if (txIsDeclined(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txHasBeenDelivered(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else {
        return { headingState: 'unknown' };
      }
    };
    const stateData = stateDataFn(currentTransaction);

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = currentListing.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'TransactionPanel.perNight'
      : isDaily
        ? 'TransactionPanel.perDay'
        : 'TransactionPanel.perUnit';

    const price = currentListing.attributes.price;
    const bookingSubTitle = price
      ? `${formatMoney(intl, price)} ${intl.formatMessage({ id: unitTranslationKey })}`
      : '';

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        agreeTerms={this.state.agreeTerms}
        onAcceptSale={() => onAcceptSale(currentTransaction.id, providerUUID, selectedDates)}
        onDeclineSale={() => onDeclineSale(currentTransaction.id)}
      />
    );

    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const handleChange = ({ values }) =>
      this.setAgreeTerms(values && values.agreeCheckbox && !!values.agreeCheckbox[0]);

    const handleReleaseShow = val => this.setReleaseShow(val);
    const handleAccidentShow = val => this.setAccidentShow(val);

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const classes = classNames(rootClassName || css.root, className);

    const uploadErrorMessage = uploadFilesError && uploadFilesError.message;

    const modalRelease = (
      <Modal
        id="modalRelease"
        containerClassName={css.modal}
        isOpen={!!this.state.releaseShow}
        onClose={() => {
          this.setState({
            releaseShow: false,
          });
        }}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage="Close"
      >
        <div>
          <h1 className={css.modalTitle}>Brand Ambassador Release Form - 2022</h1>
          <p className={css.modalParagraph}>
            In consideration of my past, present and future engagement as a model/brand ambassador,
            upon the terms herewith stated, I hereby give to Brand Gig LLC 30767 Gateway Place #179
            Rancho Mission Viejo, CA 92694 [photographer/videographer/agency/promoter/network and/or
            associates] his/her heirs, legal representatives and assigns, those for whom the
            photographer/videographer is acting, and those acting with his/her authority and
            permission:
          </p>
          <p className={css.modalParagraph}>
            a) the unrestricted right and permission to copyright and use, re-use, publish, and
            republish photographic portraits or pictures of me or in which I may be included intact
            or in part, composite or distorted in character or form, without restriction as to
            changes or transformations in conjunction with my own or a fictitious name, or
            reproduction hereof in color or otherwise, made through any and all media now or
            hereafter known for illustration, art, promotion, advertising, trade, or any other
            purpose whatsoever.
          </p>
          <p className={css.modalParagraph}>
            b) I also permit the use of any printed material in connection therewith.
          </p>
          <p className={css.modalParagraph}>
            c) I hereby relinquish any right that I may have to examine or approve the completed
            product or products or the advertising copy or printed matter that may be used in
            conjunction therewith or the use to which it may be applied.
          </p>
          <p className={css.modalParagraph}>
            d) I hereby release, discharge and agree to save harmless [photographer/
            videographer/agency/promoter/network and/or associates], his/her heirs, legal
            representatives or assigns, and all persons functioning under his/her permission or
            authority, or those for whom he/she is functioning, from any liability by virtue of any
            blurring, distortion, alteration, optical illusion, or use in composite form whether
            intentional or otherwise, that may occur or be produced in the taking of said
            picture/video or in any subsequent processing thereof, as well as any publication
            thereof, including without limitation any claims for libel or invasion of privacy.
          </p>
          <p className={css.modalParagraph}>
            e) I hereby affirm that I am over the age of eighteen (18).
          </p>
          <p className={css.modalParagraph}>
            f) I hereby agree not to conduct or be interviewed at the contracted events, provide my
            business or personal contact information to event attendees or affiliates to the event,
            use my cell phone or chew gum during contracted hours.
          </p>
          <p className={css.modalParagraph}>
            g) I hereby agree not to post any pictures on my Instagram, Facebook, Twitter, Website
            or any social media without the prior consent of Brand Gig, LLC and the proper
            event/brand media tags.
          </p>
          <p className={css.modalParagraph}>
            h) I hereby agree to be part of the promotional team and duties but acknowledge that I
            many not participate in all the following, but not limited to, activities: brand
            ambassador event attendee greeter, picture marketing brand ambassador (photographer,
            model or information gatherer), brand ambassador photo op with event attendees, brand
            ambassador trinket give-aways, and on-stage brand ambassador.
          </p>
          <p className={css.modalParagraph}>
            i) I hereby affirm that I am over the age of majority and have the right to contract in
            my own name. I have read the above authorization, release and agreement, prior to its
            execution; I fully understand the contents thereof. This agreement shall be binding upon
            me and my heirs, legal representatives and assigns.
          </p>
        </div>
      </Modal>
    );

    const modalAccident = (
      <Modal
        id="MissingInformationReminder"
        containerClassName={css.modal}
        isOpen={!!this.state.accidentShow}
        onClose={() => {
          this.setState({
            accidentShow: false,
          });
        }}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage="Close"
      >
        <div>
          <h1 className={css.modalTitle}>
            {' '}
            BRAND AMBASSADOR RELEASE, ACCIDENT WAIVER AND RELEASE OF LIABILITY FORM{' '}
            <span className={css.brand}>Brand Gig, LLC</span>
          </h1>
          <p className={css.modalParagraph}>
            I HEREBY ASSUME ALL OF THE RISKS OF PARTICIPATING AND/OR VOLUNTEERING IN THIS ACTIVITY
            OR EVENT, including by way of example and not limitation, any risks that may arise from
            negligence or carelessness on the part of the persons or entities being released, from
            dangerous or defective equipment or property owned, maintained, or controlled by them,
            or because of their possible liability without fault.
          </p>
          I certify that there are no health-related reasons or problems which preclude my
          participation in this activity or event.
          <p className={css.modalParagraph}>
            I acknowledge that this Accident Waiver and Release of Liability Form will be used by
            the event holders, sponsors, and organizers of the activity or event in which I may
            participate, and that it will govern my actions and responsibilities at said activity or
            event.
          </p>
          <p className={css.modalParagraph}>
            In consideration of my application and permitting me to participate in this event, I
            hereby take action for myself, my executors, administrators, heirs, next of kin,
            successors, and assigns as follows:
          </p>
          <p className={css.modalParagraph}>
            (A) I WAIVE, RELEASE, AND DISCHARGE from any and all liability, including but not
            limited to, liability arising from the negligence or fault of the entities or persons
            released, for my death, disability, personal injury, property damage, property theft, or
            actions of any kind which may hereafter occur to me including my traveling to and from
            this event, THE FOLLOWING ENTITIES OR PERSONS: Brand Gig, LLC and/or their contractors,
            directors, officers, employees, volunteers, representatives, and agents, the activity or
            event holders, activity or event sponsors, activity or event volunteers;
          </p>
          <p className={css.modalParagraph}>
            (B) I INDEMNIFY, HOLD HARMLESS, AND PROMISE NOT TO SUE the entities or persons mentioned
            in this paragraph from any and all liabilities or claims made as a result of
            participation in this activity or event, whether caused by the negligence of release or
            otherwise.
          </p>
          <p className={css.modalParagraph}>
            I acknowledge that Brand Gig, LLC and their contractors, directors, officers,
            volunteers, representatives, and agents are NOT responsible for the errors, omissions,
            acts, or failures to act of any party or entity conducting a specific event or activity
            on behalf of Brand Gig, LLC
          </p>
          <p className={css.modalParagraph}>
            I acknowledge that this activity or event may involve a test of a person’s physical and
            mental limits and may carry with it the potential for death, serious injury, and
            property loss. The risks may include, but are not limited to, snake or insect
            bites/stings, drowning, those caused by terrain, facilities, temperature, weather,
            condition of participants, equipment, vehicular traffic, actions of other people
            including, but not limited to, participants, volunteers, employees of Friasworks Inc and
            contractors. These risks are not only inherent to participants, but are also present for
            volunteers.
          </p>
          <p className={css.modalParagraph}>
            I hereby consent to receive medical treatment which may be deemed advisable in the event
            of injury, accident, and/or illness during this activity or event.
          </p>
          <p className={css.modalParagraph}>
            I understand that at this event or related activities, I may be photographed. I agree to
            allow my photo, video, or film likeness to be used for any legitimate purpose by the
            event holders, producers, sponsors, organizers, and assigns.
          </p>
          <p className={css.modalParagraph}>
            The accident waiver and release of liability shall be construed broadly to provide a
            release and waiver to the maximum extent permissible under applicable law.
          </p>
          <p className={css.modalParagraph}>
            I CERTIFY THAT I HAVE READ THIS DOCUMENT, AND I FULLY UNDERSTAND ITS CONTENT. I AM AWARE
            THAT THIS IS A RELEASE OF LIABILITY AND A CONTRACT AND I SIGN IT OF MY OWN FREE WILL.
          </p>
        </div>
      </Modal>
    );


    return (
      <div className={classes}>
        <div className={css.container}>
          <div className={css.txInfo}>
            <DetailCardImage
              rootClassName={css.imageWrapperMobile}
              avatarWrapperClassName={css.avatarWrapperMobile}
              listingTitle={listingTitle}
              image={firstImage}
              provider={currentProvider}
              isCustomer={isCustomer}
              listingId={currentListing.id && currentListing.id.uuid}
              listingDeleted={listingDeleted}
            />
            {isProvider ? (
              <div className={css.avatarWrapperProviderDesktop}>
                <AvatarLarge user={currentCustomer} className={css.avatarDesktop} />
              </div>
            ) : null}

            <PanelHeading
              panelHeadingState={stateData.headingState}
              transactionRole={transactionRole}
              providerName={authorDisplayName}
              customerName={customerDisplayName}
              isCustomerBanned={isCustomerBanned}
              listingId={currentListing.id && currentListing.id.uuid}
              listingTitle={listingTitle}
              listingDeleted={listingDeleted}
              brand={brand}
              event={event}
              eventLocation={eventLocation}
            />

            <div className={css.bookingDetailsMobile}>
              <AddressLinkMaybe
                rootClassName={css.addressMobile}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />
              <BreakdownMaybe
                inbox
                transaction={currentTransaction}
                transactionRole={transactionRole}
                showSaleButtons={stateData.showSaleButtons}
                handleChange={handleChange}
                agreeTerms={this.state.agreeTerms}
                handleReleaseShow={handleReleaseShow}
                handleAccidentShow={handleAccidentShow}
              />
            </div>

            {savePaymentMethodFailed ? (
              <p className={css.genericError}>
                <FormattedMessage
                  id="TransactionPanel.savePaymentMethodFailed"
                  values={{ paymentMethodsPageLink }}
                />
              </p>
            ) : null}
            <FeedSection
              rootClassName={css.feedContainer}
              currentTransaction={currentTransaction}
              currentUser={currentUser}
              fetchMessagesError={fetchMessagesError}
              fetchMessagesInProgress={fetchMessagesInProgress}
              initialMessageFailed={initialMessageFailed}
              messages={messages}
              oldestMessagePageFetched={oldestMessagePageFetched}
              onOpenReviewModal={this.onOpenReviewModal}
              onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
              totalMessagePages={totalMessagePages}
              messagesFiles={messagesFiles}
            />
            {showSendMessageForm ? (
              <SendMessageForm
                formId={this.sendMessageFormName}
                rootClassName={css.sendMessageForm}
                messagePlaceholder={sendMessagePlaceholder}
                inProgress={sendMessageInProgress}
                sendMessageError={sendMessageError}
                onFocus={this.onSendMessageFormFocus}
                onBlur={this.onSendMessageFormBlur}
                onSubmit={this.onMessageSubmit}
                filesInProgress={filesInProgress}
              />
            ) : (
              <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
            )}

            {uploadErrorMessage && <div className={css.uploadError}>{uploadErrorMessage}</div>}

            {stateData.showSaleButtons ? (
              <div className={css.mobileActionButtons}>{saleButtons}</div>
            ) : null}
          </div>

          <div className={css.asideDesktop}>
            <div className={css.detailCard}>
              <DetailCardImage
                avatarWrapperClassName={css.avatarWrapperDesktop}
                listingTitle={listingTitle}
                image={firstImage}
                provider={currentProvider}
                isCustomer={isCustomer}
                listingId={currentListing.id && currentListing.id.uuid}
                listingDeleted={listingDeleted}
              />

              <div className={classNames(css.detailBreakdownWrapper, {[css.detailBreakdownWrapperProvider]: isProvider})}>
                <DetailCardHeadingsMaybe
                  showDetailCardHeadings={stateData.showDetailCardHeadings}
                  listingTitle={listingTitle}
                  subTitle={bookingSubTitle}
                  location={location}
                  geolocation={geolocation}
                  showAddress={stateData.showAddress}
                />
                {stateData.showBookingPanel ? (
                  <BookingPanel
                    className={css.bookingPanel}
                    titleClassName={css.bookingTitle}
                    isOwnListing={false}
                    listing={currentListing}
                    title={listingTitle}
                    subTitle={bookingSubTitle}
                    authorDisplayName={authorDisplayName}
                    onSubmit={onSubmitBookingRequest}
                    onManageDisableScrolling={onManageDisableScrolling}
                    monthlyTimeSlots={monthlyTimeSlots}
                    onFetchTimeSlots={onFetchTimeSlots}
                    onFetchTransactionLineItems={onFetchTransactionLineItems}
                    lineItems={lineItems}
                    fetchLineItemsInProgress={fetchLineItemsInProgress}
                    fetchLineItemsError={fetchLineItemsError}
                    bookingAdditionalInfo={bookingAdditionalInfo}
                    onSetBookingAdditionalInfo={setBookingAdditionalInfo}
                  />
                ) : null}
                <BreakdownMaybe
                  className={css.breakdownContainer}
                  transaction={currentTransaction}
                  transactionRole={transactionRole}
                  showSaleButtons={stateData.showSaleButtons}
                  handleChange={handleChange}
                  agreeTerms={this.state.agreeTerms}
                  handleReleaseShow={handleReleaseShow}
                  handleAccidentShow={handleAccidentShow}
                  isProvider={isProvider}
                />
                {stateData.showSaleButtons ? (
                  <div className={css.desktopActionButtons}>{saleButtons}</div>
                ) : null}
              </div>

            </div>
          </div>
        </div>
        {modalRelease}
        {modalAccident}
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
        />
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  monthlyTimeSlots: null,
  nextTransitions: null,
  lineItems: null,
  fetchLineItemsError: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onFetchTimeSlots: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  monthlyTimeSlots: object,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  // line items
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
